import { stripTags } from '@lib/helpers'
import ReadMoreReadLess from '@global/ReadMoreReadLess'
import htmr from 'htmr'
import * as S from './AgentContent.styled'

const AgentContent = ({ heading, description }) => {
  return (
    <S.AgentContent>
      <S.AgentContentHeading>{htmr(stripTags(heading))}</S.AgentContentHeading>
      <ReadMoreReadLess content={description} />
    </S.AgentContent>
  )
}

export default AgentContent
