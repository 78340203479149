import Button from '@global/Button'
import IconPlus from '@icons/Plus'
import { useState } from 'react'
import IconMinus from '@icons/Minus'
import { Link } from '@routes'

import * as S from './TagList.styled'

const TagList = ({ title, items = [] }) => {
  const [showAll, setShowAll] = useState(false)

  const toggleShowAll = () => {
    setShowAll((prev) => !prev)
  }

  return (
    <S.TagListWrap>
      <h4>{title}</h4>
      <S.TagList>
        {items.map((it, index) =>
          it.path ? (
            <Link route={it.path} key={it.id} passHref>
              <S.TagListItem hidden={index > 6 && !showAll}>
                {it.title}
              </S.TagListItem>
            </Link>
          ) : (
            <S.TagListItem hidden={index > 6 && !showAll}>
              {it.title}
            </S.TagListItem>
          )
        )}
        {items.length > 7 && (
          <Button
            border
            endIcon={showAll ? <IconMinus /> : <IconPlus />}
            color='secondary'
            onClick={toggleShowAll}
          >
            {showAll ? 'View Less' : 'View More'}
          </Button>
        )}
      </S.TagList>
    </S.TagListWrap>
  )
}

export default TagList
