import React from 'react'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import { domain } from '@lib/variables'
import WagtailBlocksSection from '@global/Blocks/WagtailBlocksSection'
import AgentProfile from '@components/Agents/AgentProfile'
import getSocialLinksFromPageData from '@lib/helpers/social'

const AgentPage = ({ pageData }) => {
  const socialLinks = getSocialLinksFromPageData(pageData)

  let awards = []
  pageData.awards &&
    pageData.awards.length > 0 &&
    pageData.awards.map((award) => {
      let awardText = `${award.name}`
      if (award.year) {
        awardText += ` ${award.year}`
      }
      if (award.issuer) {
        awardText += ` (${award.issuer})`
      }
      if (award.award_type) {
        awardText += ` - ${award.award_type}`
      }
      awards.push(awardText)
    })

  let offices =
    pageData.agent_offices_list &&
    pageData.agent_offices_list.length > 0 &&
    pageData.agent_offices_list.map((office) => {
      return {
        address1: `Barry Plant ${office.office.title}`,
        phone: office.office.phone,
        address2: office.office.address,
        suburb: office.office.suburb,
        state: office.office.state,
        postcode: office.office.postcode,
        url: office.office.path,
        id: office.office.id,
        name: office.office.title,
        image: office.office.results_image_small,
      }
    })
  const canonicalURL = `${domain}${pageData.meta.path || ''}`
  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Person',
    name: pageData.title,
    url: canonicalURL,
    image:
      pageData.profile_image && pageData.profile_image.url
        ? pageData.profile_image.url
        : null,
    telephone: pageData.office_phone,
    jobTitle: pageData.job_title,
    worksFor:
      pageData.offices &&
      pageData.offices.map((office) => {
        return {
          '@type': 'RealEstateAgent',
          name: 'Barry Plant ' + office.office.title,
          areaServed: office.office.suburb,
          image: office.office.results_image_large,
          url: `${domain}${office.office.path || ''}`,
          telephone: office.office.phone,
          address: {
            '@type': 'PostalAddress',
            streetAddress: office.office.address,
            addressLocality: office.office.suburb,
            addressRegion: office.office.state,
            postalCode: office.office.postcode,
            addressCountry: 'AU',
          },
        }
      }),
  }
  const meta = pageData.meta || {}

  const suburbs = pageData.agents_suburbs_list.filter((it) => it.title)
  const regions = pageData.agents_regions_list.filter((it) => it.title)

  return (
    <>
      <Head>
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData),
          }}
        />
      </Head>
      <NextSeo
        title={meta.meta_title || pageData.title + ' / ' + pageData.job_title}
        canonical={canonicalURL}
        url={meta.canonical || canonicalURL}
        openGraph={{
          images: pageData.profile_image ? [pageData.profile_image] : null,
          title: meta.og_title || `Meet ${pageData.title}`,
          description:
            meta.og_description ||
            (offices && offices.map((o) => o.address1).join(';')),
          url: meta.canonical || canonicalURL,
        }}
      />
      <AgentProfile
        name={pageData.title}
        slug={pageData.meta.slug}
        jobTitle={pageData.job_title}
        mobile={pageData.phone}
        telephone={pageData.office_phone}
        email={pageData.email}
        showGetInTouch={pageData.show_get_in_touch}
        showSendEnquiry={pageData.show_send_enquiry}
        emailLabel={`Email ${
          pageData.name_short || pageData.title.split(' ')[0]
        }`}
        agentImg={pageData.profile_image}
        site={pageData.website_url}
        socialLinks={socialLinks}
        offices={offices}
        url={pageData.url}
        id={pageData.id}
        suburbs={suburbs}
        regions={regions}
        summaryPara={pageData.intro_text}
        infoPara={pageData.about_me}
        stats={!pageData.hide_statistics ? pageData.agent_statistics : null}
      />
      <WagtailBlocksSection blocks={pageData.content_blocks} />
    </>
  )
}

export default AgentPage
