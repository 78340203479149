import { Container } from '@styles/global.styled'
import { hostname } from '@lib/variables'
import AgentHero from './AgentHero'
import AgentInfo from './AgentInfo'
import dynamic from 'next/dynamic'

const StickyNavAgent = dynamic(() => import('./AgentInfo/StickyNavAgent'), {
  ssr: false,
})

const AgentProfile = ({
  name,
  agentImg,
  jobTitle,
  mobile,
  offices,
  telephone,
  site,
  email,
  socialLinks,
  suburbs,
  infoPara,
  summaryPara,
  stats,
  showGetInTouch,
  showSendEnquiry,
  url,
  id,
  slug,
  regions,
}) => {
  const shareObject = {
    url: `https://${hostname()}${url}`,
    quote: name + ' - ' + jobTitle,
    hastag: '#barryplant',
    hastags: ['#barryplant'],
    media: agentImg?.url || '',
    image: agentImg?.url || '',
    subject: 'Check out this real estate agent from Barry Plant',
  }

  return (
    <>
      <StickyNavAgent
        showGetInTouch={showGetInTouch}
        showSendEnquiry={showSendEnquiry}
        name={name}
        jobTitle={jobTitle}
        email={email}
        phone={mobile}
        image={agentImg?.url}
        shareObject={shareObject}
        agentId={id}
        slug={slug}
      />
      <Container>
        <AgentHero
          name={name}
          email={email}
          jobTitle={jobTitle}
          phone={mobile}
          photo={agentImg}
          agentSlug={slug}
          showGetInTouch={showGetInTouch}
          showSendEnquiry={showSendEnquiry}
          shareObject={shareObject}
        />
        <AgentInfo
          offices={offices}
          socialLinks={socialLinks}
          suburbs={suburbs}
          regions={regions}
          summaryPara={summaryPara}
          infoPara={infoPara}
          stats={stats}
        />
      </Container>
    </>
  )
}

export default AgentProfile
