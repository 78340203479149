import Stats from '@global/Stats'
import { currencyFormat } from '@lib/helpers'

const AgentStats = ({ stats }) => {
  const items = [
    {
      label: 'Properties Listed & Sold',
      value: stats?.current_sold_count || null,
    },
    {
      label: 'Properties on the market',
      value: stats?.current_listing_count || null,
    },
    {
      label: 'Median Sales Price (Last 12 mths)',
      value: stats?.median_sales_price
        ? currencyFormat(stats.median_sales_price, true)
        : null,
    },
    {
      label: 'Average days on market (Last 12 mths)',
      value: stats?.days_on_market || null,
    },
  ]

  return (
    <Stats
      title='By the numbers'
      titleStyle={{ marginBottom: 12 }}
      items={items}
    />
  )
}

export default AgentStats
