import ContactCard from '@global/ContactCard'
import { Col, Row } from '@styles/global.styled'
import TagList from './TagList'
import Social from '@global/Social'
import AgentStats from './AgentStats'
import AgentContent from './AgentContent'
import * as S from './AgentInfo.styled'

const AgentInfo = ({
  offices = [],
  socialLinks = [],
  suburbs = [],
  regions = [],
  summaryPara,
  infoPara,
  stats,
}) => {
  const primaryLocation = offices?.[0]
  const remainingOffices = offices?.length > 1 ? offices.slice(1) : []

  return (
    <S.AgentInfoWrap>
      <Row gutter={40}>
        <Col lg={8}>
          <S.AgentDetails>
            <AgentContent heading={summaryPara} description={infoPara} />
            <AgentStats stats={stats} />
            <Social title='On Social Media' socialLinks={socialLinks} />
          </S.AgentDetails>
        </Col>
        <Col lg={4}>
          <S.AgentInfoGroups>
            {primaryLocation && (
              <S.AgentInfoGroup>
                <h4>Primary Location</h4>
                <ContactCard
                  name={
                    <span>
                      Barry Plant
                      <br />
                      {primaryLocation.name}
                    </span>
                  }
                  phone={primaryLocation.phone}
                  image={primaryLocation.image}
                  link={primaryLocation.url}
                />
              </S.AgentInfoGroup>
            )}

            {suburbs.length > 0 && (
              <TagList title='Specialty suburbs' items={suburbs} />
            )}
            {regions.length > 0 && (
              <TagList title='Specialty Areas' items={regions} />
            )}

            {remainingOffices.length > 0 && (
              <S.AgentInfoGroup>
                <h4>Local offices</h4>
                {remainingOffices.map((office) => (
                  <ContactCard
                    key={office.url}
                    name={
                      <span>
                        Barry Plant
                        <br />
                        {office.name}
                      </span>
                    }
                    phone={office.phone}
                    image={office.image}
                    link={office.url}
                  />
                ))}
              </S.AgentInfoGroup>
            )}
          </S.AgentInfoGroups>
        </Col>
      </Row>
    </S.AgentInfoWrap>
  )
}

export default AgentInfo
