import Image from '@global/Image'
import Button from '@global/Button'
import { useEnquiryFormContext } from '@context/EnquiryFormContext'
import { Col, Row } from '@styles/global.styled'
import { Link } from '@routes'
import * as S from './AgentHero.styled'
import vCardCreator from '@lib/helpers/vcard'
import IconDownload from '@icons/Download'
import ShareButton from '@global/ShareButton'
import React from 'react'

const AgentHeroActions = ({
  phone,
  isMobile,
  agentSlug,
  showGetInTouch,
  email,
  showSendEnquiry,
  shareObject,
  name,
  photo
}) => {
  const { setShowEnquiryForm } = useEnquiryFormContext()

  return (
    <S.AgentHeroActions isMobile={isMobile}>
      {showGetInTouch && (
        <Link route={`/request-an-appraisal/${agentSlug}/`}>
          <Button color='secondary'>Request free Consultation</Button>
        </Link>
      )}
      <Row mbGutter={6}>
        {showSendEnquiry && email && (
          <Col xs={6} sm={6} md={12}>
            <Button
              border
              color='secondary'
              onClick={() => setShowEnquiryForm(true)}
            >
              Send Enquiry 
            </Button>
          </Col>
        )}
        <Col>
          {phone && (
            <a href={`tel:${phone}`} target='_blank'>
              <Button border color='secondary'>
                 {`Call ${phone}`}
              </Button>
            </a>
          )}
        </Col>
        {isMobile && (
          <Col>
            <S.ShareAction>
              <S.SaveContact
                onClick={() => {
                  vCardCreator.makeVCard({
                    name: name ,
                    tel: phone,
                    email,
                    photo,
                  })
                }}
              >
                <IconDownload /> Save
              </S.SaveContact>
              <ShareButton
                heading='Share this Agent'
                shareInfo={shareObject}
                modalPosition='right'
              />
            </S.ShareAction>
          </Col>
        )}
      </Row>
    </S.AgentHeroActions>
  )
}

const AgentHero = ({
  name,
  jobTitle,
  email,
  photo,
  phone,
  showGetInTouch,
  showSendEnquiry,
  agentSlug,
  shareObject
}) => {
  const [firstName, ...lastName] = name.split(' ');

  return (
    <S.AgentHero>
      <S.AgentHeroInfo>
        <div>
          <S.AgentName>
            <span>{firstName}</span>
            <span>{lastName.join(' ')}</span>
          </S.AgentName>
          <S.AgentJobTitle>{jobTitle}</S.AgentJobTitle>
        </div>
        <AgentHeroActions
          phone={phone}
          email={email}
          showGetInTouch={showGetInTouch}
          showSendEnquiry={showSendEnquiry}
          agentSlug={agentSlug}
        />
      </S.AgentHeroInfo>

      <S.AgentHeroImage>
        <Image src={photo?.url} alt={name} />
      </S.AgentHeroImage>
      <AgentHeroActions
        phone={phone}
        showGetInTouch={showGetInTouch}
        agentSlug={agentSlug}
        isMobile
        shareObject={shareObject}
        name={name}
        photo={photo}
      />
    </S.AgentHero>
  )
}

export default AgentHero
