import { DEVICES } from '@constants/global'
import styled from 'styled-components'

export const AgentInfoGroups = styled.div`
  @media ${DEVICES.mobile} {
    margin-bottom: -60px;
  }
`

export const AgentInfoGroup = styled.div`
  margin-bottom: 64px;

  h4 {
    margin-bottom: 12px;
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 60px;
  }
`

export const AgentDetails = styled.div`
  @media ${DEVICES.tabletMini} {
    padding-right: 0;
    padding-bottom: 60px;
  }
`

export const AgentInfoWrap = styled.div`
    margin-bottom: 20px;
`
