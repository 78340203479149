import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS, text2XL, text3XL } from '@styles/fonts'
import styled from 'styled-components'

export const AgentContentHeading = styled.h2`
  ${text3XL};
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  letter-spacing: 0.1px;
  margin-bottom: 40px;
  text-transform: none;

  @media ${DEVICES.mobile} {
    ${text2XL};
    letter-spacing: 0.1px;
  }
`

export const AgentContent = styled.div``
