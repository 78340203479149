import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { AgentInfoGroup } from '../AgentInfo.styled'

export const TagListWrap = styled(AgentInfoGroup)`
  h4 {
    margin-bottom: 20px;
  }
`

export const TagList = styled.div`
  margin-bottom: -10px;
`

export const TagListItem = styled.div`
  padding: 6px 14px;
  background-color: ${COLORS.nature[95]};
  color: ${COLORS.secondary};
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`
