import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS, text3XL, text4XL, textLG, textSM } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const AgentHeroInfo = styled.div`
  flex: 0 0 42%;
  width: 42%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: 32px;
  gap: 30px;
`

export const AgentName = styled.h1`
  ${text4XL};
  margin-bottom: 20px;
  color: ${COLORS.secondary};
  display: flex;
  flex-direction: column;

  span {
    display: block;
  }
`

export const AgentJobTitle = styled.h3`
  ${textLG};
  color: ${COLORS.navi};
  font-family: ${FONTS.medium};
  margin-bottom: 12px;
`

export const AgentHeroActions = styled.div`
  max-width: 265px;

  button {
    width: 100%;
    margin-bottom: 12px;
  }

  @media ${DEVICES.mobile} {
    max-width: 100%;
    margin-bottom: -12px;
    display: none;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: none;

      @media ${DEVICES.mobile} {
        display: block;
      }
    `}
`

export const AgentHeroImage = styled.div`
  flex: 1;

  height: 0;
  padding-top: 40%;
  position: relative;

  img {
    border-radius: 0 0 100px 0;
    width: 100%;
    object-fit: cover;
    object-position: top center;

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
  
  @media ${DEVICES.mobile} {
    padding-top: 75%;

    img {
      border-radius: 0 0 60px 0;
    }
  }
`

export const AgentHero = styled.div`
  display: flex;
  margin-bottom: 100px;

  @media ${DEVICES.tablet} {
    padding-top: 100px;
  }

  @media ${DEVICES.mobile} {
    padding-top: 44px;
    margin-bottom: 44px;
    flex-direction: column;

    ${AgentHeroInfo} {
      flex: 1;
      width: 100%;
      padding-bottom: 18px;
    }

    ${AgentName} {
      ${text3XL};
      margin-bottom: 12px;
    }

    ${AgentJobTitle} {
      ${textSM};
      margin-bottom: 10px;
    }

    ${AgentHeroImage} {
      margin-bottom: 28px;
    }
  }
`

export const SaveContact = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.25s ease-in;

  &:hover {
    color: ${COLORS.primary};
    text-decoration: underline;
  }

  svg {
    margin-right: 4px;
  }
`

export const ShareAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  color: ${COLORS.secondary};
`
